var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-table',{staticClass:"position-relative text-center",attrs:{"items":_vm.items,"fields":_vm.fields,"striped":"","responsive":"","hover":"","bordered":"","primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(show)",fn:function(row){return [_c('b-form-checkbox',{staticClass:"vs-checkbox-con",attrs:{"plain":""},on:{"change":row.toggleDetails},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}})]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"mb-1 d-flex",attrs:{"md":"4"}},[_c('strong',[_vm._v("Patient : ")]),_c('b-link',{attrs:{"to":{
                  name: 'patients-view',
                  params: { id: row.item.patients.id },
                }}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(row.item.patients.name)+" "+_vm._s(row.item.patients.lastname)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(row.item.patients.email))])])],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"4"}},[_c('strong',[_vm._v("Visit Date : ")]),_vm._v(_vm._s(row.item.visit_date)+" "+_vm._s(row.item.lastname)+" ")]),_c('b-col',{staticClass:"mb-1",attrs:{"md":"4"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("Visit Reason"))+": ")]),_c('span',[_vm._v(_vm._s(row.item.type_consultations && row.item.type_consultations.name))])]),_c('b-col',{staticClass:"mb-1",attrs:{"md":"4"}},[_c('strong',[_vm._v("Type Visit : ")]),(row.item.type_visits)?_c('span',[_c('span',{style:(("text-decoration: underline " + (row.item.type_visits.color)))},[_vm._v(_vm._s(row.item.type_visits.name))])]):_c('span',[_vm._v("Not Found")])])],1),_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":row.toggleDetails}},[_vm._v(" Hide Details ")])],1)]}},{key:"cell(patients)",fn:function(data){return [_c('b-link',{attrs:{"to":{
            name: 'office-visit-view-oma',
            params: { id: data.item.id },
          }}},[_c('span',[_vm._v(_vm._s(data.item.patients.name)+" "+_vm._s(data.item.patients.lastname))])])]}},{key:"cell(user_type)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('span',[_vm._v(_vm._s(data.item.user_type === "established" ? "Established" : "New"))])])]}},{key:"cell(type_visits)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(data.item.type_visits.name),expression:"data.item.type_visits.name",modifiers:{"hover":true,"top":true}}],staticClass:"badge-glow",style:({ 'background-color': data.item.type_visits.color })},[_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.type_visits.prefix))])])],1)]}},{key:"cell(nurse)",fn:function(data){return [(data.item.office_visit.nurses)?_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.office_visit.nurses.avatar,"variant":"light-primary"}})]},proxy:true}],null,true)},[_c('b-link',{attrs:{"to":{
              name: 'web-settings-users',
              params: {
                id: data.item.office_visit.nurses.id,
              },
            }}},[_c('span',{staticClass:"\n                font-weight-bold\n                text-nowrap\n                d-flex\n                flex-column\n                align-items-start\n              "},[_c('span',[_vm._v(" "+_vm._s(data.item.office_visit.nurses.name)+" ")]),_c('span',[_vm._v(" "+_vm._s(data.item.office_visit.nurses.lastname)+" ")])])])],1):_vm._e()]}},{key:"cell(oma)",fn:function(data){return [(data.item.office_visit.omas)?_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.office_visit.omas.avatar,"variant":"light-primary"}})]},proxy:true}],null,true)},[_c('b-link',{attrs:{"to":{
              name: 'web-settings-users',
              params: {
                id: data.item.office_visit.omas.id,
              },
            }}},[_c('span',{staticClass:"\n                font-weight-bold\n                d-block\n                text-nowrap\n                d-flex\n                flex-column\n                align-items-start\n              "},[_c('span',[_vm._v(" "+_vm._s(data.item.office_visit.omas.name)+" ")]),_c('span',[_vm._v(" "+_vm._s(data.item.office_visit.omas.lastname)+" ")])])])],1):_vm._e()]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(data.item.office_visit.visit_statuses.name),expression:"data.item.office_visit.visit_statuses.name",modifiers:{"hover":true,"top":true}}],staticClass:"badge-glow cursor-pointer",style:({
              'background-color': data.item.office_visit.visit_statuses.color,
            })},[_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.office_visit.visit_statuses.prefix))])])],1)]}},{key:"cell(time)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('span',[_vm._v(_vm._s(data.item.updated_at.split("T")[1].split(".")[0]))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }