<template>
  <b-card v-if="data" class="card-tiny-line-stats" body-class="pb-50">
    <h3 class="font-weight-bolder m-0 mb-1">Daily Income Goal</h3>
    <p class="text-muted">Income daily for this clinic</p>

    <h4>$ {{ data.daily_goal }}</h4>
  </b-card>
</template>

<script>
import { BCard, BBadge, BProgress, VBTooltip } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BBadge,
    BProgress,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {},
  data() {
    return {
      tooltipTotal:
        "Es el número de pacientes atendidos por cada OMA en 1 mes.",
      tooltipPriority:
        "Es la sumatoria del número de pacientes atendidos en las categorías de NP y ES por cada OMA en 1 mes.",
      tooltipValue:
        "Es la sumatoria del número de pacientes atendidos en las categorías de NP, ES y Others por cada OMA en 1 mes.",
    };
  },
  methods: {},
};
</script>
