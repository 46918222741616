<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-header class="d-flex justify-content-between">
      <b-card-title>{{
        $t("DashboardView.Individual Statistics")
      }}</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0"> </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item, i) in data"
          :key="i"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2" @click="openModal(item)">
              <b-avatar
                size="48"
                :variant="item.color"
                v-b-tooltip.hover.top="item.hover"
                :class="item.isExplanation ? 'cursor-pointer' : ''"
              >
                <feather-icon v-if="!item.icon.isImg" size="24" :icon="item.icon.src" />
                <b-img v-else height="24" width="24" :src="item.icon.src"></b-img>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.isNumber ? "" : "$" }} {{ item.cant }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
    <b-modal
      id="modal-1"
      :title="itemName"
      hide-footer
      size="sm"
      centered
      :visible="modalShow"
      @hide="modalShow = false"
      body-class="p-0"
    >
      <ul class="list-group d-block">
        <li
          v-for="(item, i) in itemAvg"
          :key="i"
          class="list-group-item d-flex align-items-center"
        >
          <div
            style="width: 15px; height: 15px"
            class="bg-primary rounded-lg"
          ></div>
          <span class="ml-1"> {{ item.name }}: ${{ item.value }} </span>
        </li>
      </ul>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  VBTooltip,
  BModal,
  BImg
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BModal,
    BImg
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modalShow: false,
      itemAvg: {},
      itemName: "",
    };
  },
  mounted() {
    this.data.forEach((item) => {
      if (item.subtitle === "VPD" || item.subtitle === "Transactions") {
        item.isNumber = true;
      }
    });
  },
  methods: {
    getAvgName(avgName) {
      if (typeof avgName === "string") {
        return avgName;
      }
      if (typeof avgName === "object" && Object.keys(avgName).length > 0) {
        const element = avgName.priorityTotal || avgName.total;
        return element;
      }
      return avgName;
    },
    openModal(item) {
      if (item.isExplanation !== false) {
        this.modalShow = true;
        this.itemAvg = item.explanation;
        this.itemName = item.subtitle;
      }
    },
  },
};
</script>
