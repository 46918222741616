<template>
  <div>
    <!-- pasar codigo html a vue -->
    <b-card>
      <div class="h-50 d-flex justify-content-between mb-2">
        <div v-if="!isAdmin">
          <h3 class="font-weight-bold m-0">OMA Report</h3>
          <p class="text-muted">indivual overview</p>
        </div>
        <div v-else style="width: 20rem">
          <v-select
            v-model="selectedOma"
            id="mySelect"
            class="w-100"
            :clearable="false"
            :options="omaOptions"
            :reduce="option => option.id"
            label="name"
            @search="searchOma"
            @input="selectStatsOma"
          >
            <template slot="option" slot-scope="option">
              <SelectUser :option="option" />
            </template>
            <template slot="selected-option" slot-scope="option">
              <SelectUser :option="option" />
            </template>
          </v-select>
        </div>
        <div class="text-center" v-if="data">
          <h4 class="font-weight-bold m-0">Ticket AVG</h4>
          <p class="font-weight-bold">$ {{ data.ticketAvg }}</p>
        </div>
        <!-- agregar opcion de siempre tener uno seleccionado -->
        <v-select
          v-model="changeDate"
          id="mySelect"
          class="w-25"
          :clearable="false"
          :options="datesChart"
          @input="selectSection(0)"
        />
      </div>
      <div class="d-flex overflow-auto">
        <div
          class="border-custom cursor-pointer rounded px-1 py-1 mr-1"
          v-for="(section, index) in selectStats"
          :key="index"
          :class="index === selectedSection ? 'border-dotted' : 'border-muted'"
          @click="selectSection(index)"
        >
          <div
            class="d-flex flex-column justify-content-center align-items-center text-center"
          >
            <b-avatar
              size="35"
              :variant="
                index === selectedSection ? 'light-primary' : 'light-secondary'
              "
              rounded="lg"
            >
              <feather-icon
                :icon="section.icon"
                size="20"
                :class="
                  index === selectedSection ? 'text-primary' : 'text-muted'
                "
              />
            </b-avatar>
            <p class="mb-0 padding-top">{{ section.title }}</p>
          </div>
        </div>
      </div>
      <div id="chart">
        <vue-apex-charts
          type="bar"
          height="300"
          :options="dataApex.chartOptions"
          :series="dataApex.series"
        ></vue-apex-charts>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BTabs, BTab, BAvatar } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";

import SelectUser from "@/components/SelectUser";

import axiosUsuario from "@/services/admin/user";
import axiosDashboard from "@/services/dashboard";

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    VueApexCharts,
    vSelect,
    SelectUser,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectStats: this.arrayStats(),
      datesChart: ["Weekly", "Monthly"],
      changeDate: "Weekly",
      selectedSection: 0,
      omaOptions: [],
      selectedOma: null,
      clearTimeoutBuscador: null,
      dataApex: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: "bar",
            fontFamily: "Poppins",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: "top", // top, center, bottom
              },
              columnWidth: "50%",
              distributed: false, // flat, rounded, squared, butt
            },
          },
          colors: ["#5A8DEE"],
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: -25,
            /* poner la letra gruesa */
            style: {
              fontFamily: "Poppins",
              fontSize: "15px",
              colors: ["#000"],
            },
          },
          tooltip: {
            enabled: false, // Configura esto a false para ocultar el tooltip
          },
          xaxis: {
            categories: [],
            position: "bottom",
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              formatter: function (val) {
                return val;
              },
            },
          },
          title: {
            text: "Monthly Inflation in Argentina, 2002",
            floating: true,
            offsetY: 330,
            align: "center",
            style: {
              color: "#444",
            },
          },
        },
      },
    };
  },
  mounted() {
    if (this.isAdmin) {
      this.getOma();
    } else {
      this.getData();
    }
  },
  methods: {
		arrayStats(){
			let stats = [
        {
          title: "VPD",
          icon: "BarChartIcon",
        },
        {
          title: "NP",
          icon: "BarChartIcon",
        },
        {
          title: "ES",
          icon: "BarChartIcon",
        },
        {
          title: "P. VPD",
          icon: "BarChartIcon",
        },
        {
          title: "V. VPD",
          icon: "BarChartIcon",
        },
				{
					title: "Income",
          icon: "DollarSignIcon",
				},
        {
          title: "NP. AVG",
          icon: "DollarSignIcon",
        },
        {
          title: "ES. AVG",
          icon: "DollarSignIcon",
        },
        {
          title: "P. AVG",
          icon: "DollarSignIcon",
        },
        {
          title: "V. AVG",
          icon: "DollarSignIcon",
        },
      ]
			if(this.isAdmin){
				return stats
			}
		// const statsNoAdmin = stats.splice(4,1)
			const indexToRemove = 5; // La quinta posición tiene un índice de 4 (las posiciones se cuentan desde 0)
			const filteredStats = stats.filter((_, index) => index !== indexToRemove);
			return filteredStats
		},
    getData() {
      const { weekly } = this.data;
      const dataEs = weekly.es;

      let dataDasboard = {
        nombre: [],
        data: [],
      };

      dataEs.forEach((item) => {
        dataDasboard.nombre.push(item.week);
        dataDasboard.data.push(item.total);
      });

      this.dataApex = {
        series: [
          {
            data: dataDasboard.data,
          },
        ],
        chartOptions: {
          xaxis: {
            categories: dataDasboard.nombre,
          },
        },
      };
    },
    selectSection(index) {
      this.selectedSection = index;

      const selectDate = {
        Weekly: "weekly",
        Monthly: "monthly",
      };

      const selectStats = this.isAdmin === true ? {//var admin
        ES: "es",
        NP: "np",
        VPD: "vpd",
        "P. VPD": "priorityvpd",
        "V. VPD": "valuevpd",
				"Income": "income",
        "NP. AVG": "npavg",
        "P. AVG": "priorityavg",
        "V. AVG": "valueavg",
        "ES. AVG": "esavg",
      } : {//var admin
        ES: "es",
        NP: "np",
        VPD: "vpd",
        "P. VPD": "priorityvpd",
        "V. VPD": "valuevpd",
        "NP. AVG": "npavg",
        "P. AVG": "priorityavg",
        "V. AVG": "valueavg",
        "ES. AVG": "esavg",
      };

      const dataDate = this.data[selectDate[this.changeDate]];
      const dataEs = dataDate[selectStats[this.selectStats[index].title]];

      let dataDasboard = {
        nombre: [],
        data: [],
      };

      dataEs.forEach((item) => {
        dataDasboard.nombre.push(item.month || item.week);
        dataDasboard.data.push(
          parseFloat(item.total.toString().replace(",", ""))
        );
      });

      this.dataApex = {
        series: [
          {
            data: dataDasboard.data,
          },
        ],
        chartOptions: {
          xaxis: {
            categories: dataDasboard.nombre,
          },
        },
      };
    },
    getOma() {
      const data = {
        role: "OMA",
				status:true
      };
      axiosUsuario
        .userFilter(20, data)
        .then(({ registro: { data } }) => {
          this.omaOptions = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchOma(name, loading) {
      if (name.length) {
        const datos = {
          name,
          role: ["OMA"],
					status:true
        };
        loading(true);
        clearTimeout(this.clearTimeoutBuscador);
        this.clearTimeoutBuscador = setTimeout(() => {
          axiosUsuario.userFilter(10, datos).then(({ registro: { data } }) => {
            this.omaOptions = data;
            loading(false);
          });
        }, 400);
      }
    },
    selectStatsOma(){
      axiosDashboard.dashboardOmaStatistics(this.selectedOma).then((data) => {
        this.data = data;
        this.getData();
      });
    }
  },
};
</script>

<style scoped lang="scss">
.border-custom {
  border: 1.5px dotted #1d1b1b;
}
.border-dotted {
  border: 1.5px dotted #5a8dee;
}
.padding-top {
  padding-top: 5px;
}
</style>
