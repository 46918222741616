import { userAxios } from "../index";

const dashboardList = async () => {
    try {
        return await userAxios.get(`dashboard`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const dashboardAdminList = async () => {
    try {
        return await userAxios.get(`dashboard/superadmin`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const patientStats = async (date) => {
    try {
        return await userAxios.get(`dashboard/superadmin/patients/count/${date}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const dashboardOmaStatistics = async (omaId) => {
    try {
        return await userAxios.get(`dashboard/super-admin/${omaId}/oma-statistics`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const dashboardGoalDetails = async () => {
    try {
        return await userAxios.get(`dashboard/facility`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}


export default {
    dashboardList,
    dashboardAdminList,
    patientStats,
    dashboardGoalDetails,
    dashboardOmaStatistics
}