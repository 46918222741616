<template>
  <b-card v-if="data" body-class="p-1">
    <b-row class="h-100">
      <b-col cols="12">
        <h2 class="font-weight-bold m-0">$ {{ this.data.current_avg }}</h2>
        <p class="text-muted">{{ $t("DashboardView.Daily Progress") }}</p>
      </b-col>
      <b-col cols="12">
        <vue-apex-charts
          type="radialBar"
          :series="MonthStats.series"
          :options="MonthStats.chartOptions"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

const $barColor = "#f3f3f3";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      MonthStats: {
        series: [this.data.percentaje_current],
        chartOptions: {
          chart: {
            type: "radialBar",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "99%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#999",
                },
              },
              hollow: {
                size: "70%",
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -8,
                  fontWeight: 700,
                  fontSize: "22px",
                },
              },
            },
          },
          fill: {
            type: "solid",
            colors: [$themeColors.warning],
          },
          stroke: {
            lineCap: "round",
          },
          labels: ["Average Results"],
        },
      },
    };
  },
};
</script>
