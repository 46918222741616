<template>
  <b-card no-body>
    <b-card-header class="d-flex">
      <!-- title and subtitle -->
      <div>
        <b-card-sub-title class="mb-25"> Balance </b-card-sub-title>
        <b-card-title class="font-weight-bolder"> $ {{ total }} </b-card-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <v-select
          v-model="changeDate"
          class="style-chooser"
          :options="datesChart"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="300"
        :options="sessionsByFacility.chartOptions"
        :series="sessionsByFacility.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";

import { $themeColors } from "@themeConfig";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    vSelect,
  },
  props: {
    facilityDasboard: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    changeDate(newValue) {
      if (newValue === null) {
        this.changeDate = "Last Day";
        return;
      }
      this.getData(newValue);
    },
  },
  data() {
    return {
      total: 0,
      datesChart: ["Last Day", "Last Week", "Last Month"],
      changeDate: "Last Day",
      sessionsByFacility: {
        series: [
          {
            name: "Income",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            fontFamily: "Poppins",
            toolbar: {
              show: false,
            },
          },
          colors: $themeColors.primary,
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "30%",
              endingShape: "rounded",
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    };
  },
  methods: {
    getData(date = "Last Day") {
      let visitDate;
      this.total = 0;
      switch (date) {
        case "Last Day":
          visitDate = this.facilityDasboard.day_stats;
          /* this.typesAll = this.facilityDasboard.visits_day.types; */
          break;
        case "Last Week":
          visitDate = this.facilityDasboard.week_stats;
          /* this.typesAll = this.facilityDasboard.week_stats.types; */
          break;
        case "Last Month":
          visitDate = this.facilityDasboard.month_stats;
          /* this.typesAll = this.facilityDasboard.month_stats.types; */
          break;
      }

      let dataDasboard = {
        nombre: [],
        income: [],
      };

      visitDate.forEach((item) => {
        dataDasboard.nombre.push(item.name);
        dataDasboard.income.push(item.income);
        this.total += item.income;
      });

      this.sessionsByFacility = {
        series: [
          {
            data: dataDasboard.income,
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "60%",
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false, //or just here to disable only y axis
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: dataDasboard.nombre,
            labels: {
              show: true,
              trim: false,
              style: {
                colors: $themeColors.primary,
              },
            },
          },
          yaxis: {
            labels: {
              show: true,
              trim: false,
              style: {
                colors: $themeColors.primary,
              },
            },
          },
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>