<template>
  <b-card v-if="data" no-body class="justify-content-between">
    <b-card-header class="d-flex">
      <h4 class="mb-0">Goal Overview</h4>
      <b-button
        variant="flat-primary"
        class="btn-icon btn-sm"
        v-b-tooltip.hover.top="'View Goals'"
        :to="{ name: 'resource-goal-score' }"
      >
        <feather-icon icon="CornerUpRightIcon" size="16" />
      </b-button>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="245"
      class="my-2"
      :options="goalDashboard.chartOptions"
      :series="goalDashboard.series"
    />
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0"> My Score </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ data.user_score }}
        </h3>
      </b-col>

      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0"> Goal Score </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ data.goal_score }}
        </h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BRow, BCol, BCardText, BButton, VBTooltip, } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

const $strokeColor = "#ebe9f1";
const $textHeadingColor = "#5e5873";
const $goalStrokeColor2 = "#51e5a8";
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    BButton,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      goalDashboard: {
        series: [this.data.percentage],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "77%",
              },
              track: {
                background: $strokeColor,
                strokeWidth: "50%",
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: $textHeadingColor,
                  fontSize: "2.86rem",
                  fontWeight: "600",
                },
              },
            },
          },
          colors: [$goalStrokeColor2],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
    };
  },
};
</script>
