<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and legend -->
      <b-card-title class="mb-50"> Types Of Visits Per Week </b-card-title>
      <!--/ title and legend -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="400"
        :options="columnChart.chartOptions"
        :series="columnChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

import { $themeColors } from "@themeConfig";

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    BCardTitle,
  },
  props: {
    dashboardData: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      columnChart: {
        series: [],
        chartOptions: {
          chart: {
            stacked: true,
            fontFamily: "Poppins",
            toolbar: {
              show: false,
            },
          },
          colors: ["#826af9", "#d2b0ff"],
          plotOptions: {
            bar: {
              columnWidth: "15%",
              colors: {
                backgroundBarColors: [
                  "#f8d3ff",
                  "#f8d3ff",
                  "#f8d3ff",
                  "#f8d3ff",
                  "#f8d3ff",
                ],
                backgroundBarRadius: 10,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: "top",
            fontSize: "14px",
            fontFamily: "Poppins",
            horizontalAlign: "left",
          },
          stroke: {
            show: true,
            colors: ["transparent"],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ],
          },
          yaxis: {
            // opposite: isRtl,
          },
          fill: {
            opacity: 1,
          },
        },
      },
    };
  },
  methods: {
    getData() {
      let dataSeries = [];
      let colorChart = [];
      this.dashboardData.forEach((item) => {
        dataSeries.push({
          name: item.name,
          data: Object.values(item.week),
        });
        colorChart.push(item.color);
      });

      console.log(dataSeries);

      this.columnChart = {
        series: dataSeries,
        chartOptions: {
          chart: {
            stacked: true,
            fontFamily: "Poppins",
            toolbar: {
              show: false,
            },
          },
          colors: colorChart,
          plotOptions: {
            bar: {
              columnWidth: "15%",
              colors: {
                backgroundBarColors: [
                  $themeColors.primary,
                  $themeColors.primary,
                  $themeColors.primary,
                  $themeColors.primary,
                ],
                backgroundBarRadius: 10,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: "top",
            fontSize: "14px",
            fontFamily: "Poppins",
            horizontalAlign: "left",
          },
          stroke: {
            show: true,
            colors: ["transparent"],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ],
          },
          yaxis: {
            // opposite: isRtl,
          },
          fill: {
            opacity: 1,
          },
        },
      };
    },
  },
};
</script>
