<template>
  <b-card v-if="data" class="earnings-card">
    <b-row>
      <b-col cols="12" class="d-flex justify-content-between mb-2">
        <div class="d-flex align-items-center ">
          <b-avatar src="@/assets/images/icons/information.svg" class="bg-transparent" />
          <h3 class="ml-1 mb-0">Information</h3>
        </div>
        <div>
          <feather-icon size="24" icon="MoreVerticalIcon" />
        </div>
      </b-col>
      <b-col cols="12">
        <p>
          The development of Vue and its ecosystem is guided by the needs of the
          community. If you need help with Vue, please post your question on the
          forum or chat with the community and the core team in real-time.
        </p>
        <div class="d-flex justify-content-between align-items-center">
          <b-avatar-group class="" size="34px">
            <b-avatar
              v-for="avatar in data.avatars"
              :key="avatar.avatar"
              :src="avatar.avatar"
              class="pull-up"
            />
            <h6 class="align-self-center cursor-pointer ml-1 mb-0">+42</h6>
          </b-avatar-group>
          <div>
            <b-badge
              v-for="(label, index) in labels"
              :key="index"
              variant="light-primary"
              class="mr-1"
              >{{ label }}</b-badge
            >
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatarGroup,
  BAvatar,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardTitle,
    BCardText,
    BAvatarGroup,
    BAvatar,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      labels: ["Vuejs", "Developer"],
    };
  },
};
</script>
