<template>
  <div>
    <b-card>
      <b-table
        :items="items"
        :fields="fields"
        striped
        responsive
        hover
        bordered
        class="position-relative text-center"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(show)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="4" class="mb-1 d-flex">
                <strong>Patient : </strong>
                <b-link
                  :to="{
                    name: 'patients-view',
                    params: { id: row.item.patients.id },
                  }"
                >
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ row.item.patients.name }}
                    {{ row.item.patients.lastname }}
                  </span>
                  <small class="text-muted">{{
                    row.item.patients.email
                  }}</small>
                </b-link>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Visit Date : </strong>{{ row.item.visit_date }}
                {{ row.item.lastname }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>{{ $t("Visit Reason") }}: </strong
                ><span>{{
                  row.item.type_consultations &&
                  row.item.type_consultations.name
                }}</span>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Type Visit : </strong>
                <span v-if="row.item.type_visits">
                  <span
                    :style="`text-decoration: underline ${row.item.type_visits.color}`"
                    >{{ row.item.type_visits.name }}</span
                  >
                </span>
                <span v-else>Not Found</span>
              </b-col>
            </b-row>

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>
        <template #cell(patients)="data">
          <b-link
            :to="{
              name: 'office-visit-view-oma',
              params: { id: data.item.id },
            }"
          >
            <span
              >{{ data.item.patients.name }}
              {{ data.item.patients.lastname }}</span
            >
          </b-link>
        </template>

        <template #cell(user_type)="data">
          <div class="text-nowrap">
            <span>{{
              data.item.user_type === "established" ? "Established" : "New"
            }}</span>
          </div>
        </template>

        <template #cell(type_visits)="data">
          <div class="text-nowrap">
            <b-badge
              :style="{ 'background-color': data.item.type_visits.color }"
              v-b-tooltip.hover.top="data.item.type_visits.name"
              class="badge-glow"
            >
              <span class="align-text-top text-capitalize">{{
                data.item.type_visits.prefix
              }}</span>
            </b-badge>
          </div>
        </template>
        <template #cell(nurse)="data">
          <b-media v-if="data.item.office_visit.nurses" vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.office_visit.nurses.avatar"
                variant="light-primary"
              />
            </template>
            <b-link
              :to="{
                name: 'web-settings-users',
                params: {
                  id: data.item.office_visit.nurses.id,
                },
              }"
            >
              <span
                class="
                  font-weight-bold
                  text-nowrap
                  d-flex
                  flex-column
                  align-items-start
                "
              >
                <span>
                  {{ data.item.office_visit.nurses.name }}
                </span>
                <span>
                  {{ data.item.office_visit.nurses.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>
        <template #cell(oma)="data">
          <b-media v-if="data.item.office_visit.omas" vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.office_visit.omas.avatar"
                variant="light-primary"
              />
            </template>
            <b-link
              :to="{
                name: 'web-settings-users',
                params: {
                  id: data.item.office_visit.omas.id,
                },
              }"
            >
              <span
                class="
                  font-weight-bold
                  d-block
                  text-nowrap
                  d-flex
                  flex-column
                  align-items-start
                "
              >
                <span>
                  {{ data.item.office_visit.omas.name }}
                </span>
                <span>
                  {{ data.item.office_visit.omas.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge
              :style="{
                'background-color': data.item.office_visit.visit_statuses.color,
              }"
              v-b-tooltip.hover.top="data.item.office_visit.visit_statuses.name"
              class="badge-glow cursor-pointer"
            >
              <span class="align-text-top text-capitalize">{{
                data.item.office_visit.visit_statuses.prefix
              }}</span>
            </b-badge>
          </div>
        </template>
        <template #cell(time)="data">
          <div class="text-nowrap">
            <span>{{ data.item.updated_at.split("T")[1].split(".")[0] }}</span>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";

import axiosA from "@/services/office-visit";

export default {
  components: {
    BTable,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    VBTooltip,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mounted() {
    this.getAppointments();
  },
  data() {
    return {
      fields: [
        { key: "show", class: "th-class-adjusted" },
        { key: "id", sortable: true, class: "th-class-adjusted" },
        {
          key: "patients",
          label: "Patient",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "visit_date",
          label: "Visit Date",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "user_type",
          label: "Type user",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "type_visits",
          label: "Type Visit",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "office_visit.room",
          label: "Room",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "nurse",
          label: "Nurse",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "oma",
          label: "OMA",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          class: "th-class-adjusted",
        },
        {
          key: "time",
          label: "Time",
          sortable: true,
          class: "th-class-adjusted",
        },
      ],
      items: [],
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      isSortDirDesc: true,
      sortBy: "id",
      isSortDirDesc: true,
    };
  },
  methods: {
    getAppointments() {
      axiosA
        .officeVisitList(this.perPage)
        .then(({ total, current_page, ...res }) => {
          this.items = res.data;
        });
    },
  },
};
</script>

<style lang="scss">
.th-class-adjusted {
  padding: 0.9rem 0.1rem !important;
}
</style>
