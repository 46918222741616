<template>
  <div>
    <div>
      <b-modal
        v-model="isBanner"
        centered
        scrollable
        hide-footer
        hide-header
        body-class="p-0"
        @hide="onHide"
      >
        <b-carousel
          id="carousel-crossfade"
          controls
          indicators
          interval="40000"
        >
          <b-carousel-slide
            v-for="(banner, index) in images"
            :key="index"
            :caption-html="banner.type === 1 && banner.text"
            :class="getClassForBanner(banner)"
            :img-blank="banner.type === 1"
            :img-src="banner.dir_img"
          >
            <!-- <template #img>
              <img class="img-custom" :src="banner.dir_img" alt="image slot" />
            </template> -->
          </b-carousel-slide>
        </b-carousel>
      </b-modal>
    </div>
    <!-- <b-modal
      v-if="bannerText.length > 0"
      v-model="isBannerText"
      centered
      scrollable
      hide-footer
      hide-header
      size="lg"
      body-class="p-0"
    >
      <b-carousel
        id="carousel-fade"
        fade
        indicators
        img-height="10000"
      >
        <b-carousel-slide
          class="ql-editor"
          :caption-html="bannerText[0].text"
          img-blank
        ></b-carousel-slide>
      </b-carousel>
    </b-modal> -->

    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      rounded="lg"
    >
      <component
        v-if="dashboardRol[rolMain.id]"
        :is="dashboardRol[rolMain.id]"
      />
      <template v-else>
        <DashboardMedical />
      </template>
    </b-overlay>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import {
  BRow,
  BCol,
  BModal,
  BCarousel,
  BCarouselSlide,
  BOverlay,
  BAlert,
} from "bootstrap-vue";

import DashboardAdmin from "./DashboardAdmin";
import DashboardMedical from "./DashboardMedical";
import DashboardFrontdesk from "./DashboardFrontdesk";

import axiosT from "@/services/admin/template";
import axiosC from "@/services/admin/config";

import store from "@/store";
export default {
  components: {
    BRow,
    BCol,
    BModal,
    BCarousel,
    BCarouselSlide,
    BOverlay,
    BAlert,

    DashboardAdmin,
    DashboardMedical,
    DashboardFrontdesk,
  },
  data() {
    return {
      isBanner: false,
      isBannerText: false,
      data: {},
      images: [],
      bannerText: [],
      dashboard: null,
      loading: false,
      rolMain: JSON.parse(localStorage.getItem("userData")).roles[0],
      dashboardDefault: "DashboardMedical",
      dashboardRol: {
        1: "DashboardAdmin",
        5: "DashboardFrontdesk",
      },
    };
  },
  created() {
    // auth pusher
    // this.$pusher.config.auth.headers.Authorization = `Bearer ${localStorage.getItem(
    //   "accessToken"
    // )}`;

    const data = JSON.parse(localStorage.getItem("userData"));

    this.getImagesActive();
    this.getColor();
  },
  methods: {
    getColor() {
      axiosC.configActive().then(({ color }) => {
        if (color == "light") {
          localStorage.setItem("myclinicform-navbar-bg", "");
          store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
            backgroundColor: "",
          });
          return;
        }
        localStorage.setItem("myclinicform-navbar-bg", color);
        store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
          backgroundColor: color,
        });
      });
    },
    getImagesActive() {
      axiosT.bannerActive().then(({ registro }) => {
        this.isBanner = registro.length > 0;
        this.images = registro;
        this.bannerText = registro.filter((item) => item.type === 1);
      });
    },
    onHide() {
      this.isBanner = false;
      this.isBannerText = true;
    },
    getClassForBanner(banner) {
      if (banner.type === 1) {
        return "banner-text-modal";
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.modal-body {
  padding: none !important;
}

.img-custom {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: auto;
}
.banner-text-modal {
  height: 90vh;
  overflow-y: scroll;
  padding: 1rem;

  .carousel-caption {
    max-height: 90vh;
    padding: 3rem 0;
  }
}
</style>
