<template>
  <b-card no-body class="mb-1">
    <b-card-body class="d-flex flex-column align-items-center justify-content-around p-1">
      <b-avatar variant="success" size="45">
        <feather-icon size="21" icon="UsersIcon" />
      </b-avatar>
      <div class="truncate">
        <h2 class="font-weight-bolder text-center mt-1">
          {{ patientTotal.total }}
        </h2>
        <span>{{ patientTotal.name }}</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    patientTotal: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  methods: {},
};
</script>
