<template>
  <b-card v-if="data">
    <b-row class="pb-50">
      <!-- text and button -->
      <b-col
        sm="4"
        order-sm="1"
        order="2"
        class="d-flex justify-content-between flex-column mt-1 mt-sm-0"
      >
        <div>
          <h2 class="font-weight-bold mb-25">Facility Report</h2>
          <p class="text-muted">Monthly total income</p>
          <div class="d-flex">
            <h3 class="font-weight-bolder mr-2">$ {{ data.goal }}</h3>

            <h5 class="font-medium-2">
              <span>
                <b-badge variant="light-success"
                  >{{ data.percentaje }}%</b-badge
                >
              </span>
            </h5>
          </div>
        </div>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="shadow"
          @click="$emit('showGoals', true)"
        >
          <span>View Details </span>
          <feather-icon icon="ChevronsRightIcon" />
        </b-button>
      </b-col>

      <!-- dropdown and chart -->
      <b-col sm="8" cols="12" order-sm="2" order="1">
        <!-- apex chart -->
        <vue-apex-charts
          type="bar"
          height="200"
          :options="statisticsGoal.chartOptions"
          :series="statisticsGoal.series"
        />
      </b-col>
    </b-row>
    <hr />

    <!-- progress bar -->
    <b-row class="avg-sessions mx-1 p-1 border rounded">
      <b-col cols="4" v-b-tooltip.hover.top="tooltipTotal">
        <div class="d-flex align-items-center">
          <b-avatar rounded="lg" variant="warning" style="margin: 6px"
            ><feather-icon size="20" icon="BarChartIcon"
          /></b-avatar>
          <h4 class="font-weight-bolder">Total VPD</h4>
        </div>
        <h3 class="mb-50">{{ cash.total }}</h3>
        <b-progress
          :value="cash.total"
          :max="data.goal"
          height="6px"
          variant="warning"
        />
      </b-col>
      <b-col cols="4" v-b-tooltip.hover.top="tooltipPriority">
        <div class="d-flex align-items-center">
          <b-avatar rounded="lg" variant="success" style="margin: 6px"
            ><feather-icon size="20" icon="BarChartIcon"
          /></b-avatar>
          <h4 class="font-weight-bolder">Priority VPD</h4>
        </div>
        <h3 class="mb-50">{{ cash.priotity }}</h3>
        <b-progress
          variant="success"
          :value="cash.priotity"
          :max="data.goal"
          height="6px"
        />
      </b-col>
      <b-col cols="4" v-b-tooltip.hover.top="tooltipValue">
        <div class="d-flex align-items-center">
          <b-avatar rounded="lg" variant="info" style="margin: 6px"
            ><feather-icon size="20" icon="BarChartIcon"
          /></b-avatar>
          <h4 class="font-weight-bolder">Value VPD</h4>
        </div>
        <h3 class="mb-50">{{ cash.value }}</h3>
        <b-progress
          :value="cash.value"
          :max="data.goal"
          height="6px"
          variant="info"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BProgress,
  BCardText,
  BAvatar,
  BBadge,
  VBTooltip
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
import { $themeColors } from "@themeConfig";
import { kFormatter } from "@core/utils/filter";

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
    BAvatar,
    BBadge,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    cash: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    summaryTotal() {
      return parseInt(this.cash.cc) + parseInt(this.cash.cash);
    },
  },
  data() {
    return {
      rolMain: JSON.parse(localStorage.getItem("userData")).roles[0],
      statisticsGoal: {
        series: [
          {
            name: "Type Visit",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
          },
          grid: {
            show: true,
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "12px",
              fontFamily: "Poppins",
              colors: ["#fff"],
            },
            formatter: function (val) {
              if (val == 0) return "";
              return val;
            },
          },
          colors: [],
          plotOptions: {
            bar: {
              columnWidth: "35%",
              distributed: true,
            },
          },
          tooltip: {
            x: { show: true },
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          legend: {
            show: false,
          },
        },
      },
      tooltipTotal: "Es el número de pacientes atendidos por cada clínica en 1 mes.",
      tooltipPriority: "Es la sumatoria del número de pacientes atendidos en las categorías de NP y ES por cada clínica en 1 mes.",
      tooltipValue: "Es la sumatoria del número de pacientes atendidos en las categorías de NP, ES y Others por cada clínica en 1 mes.",
    };
  },
  methods: {
    kFormatter,
    getData() {
      const { facility } = this.data;
      const { types, others } = facility;

      let dataDasboard = {
        nombre: [],
        prefix: [],
        data: [],
        colores: [],
      };

      types.forEach((item) => {
        dataDasboard.nombre.push(item.name);
        dataDasboard.prefix.push(item.prefix);
        dataDasboard.data.push(item.sum);
        dataDasboard.colores.push(item.color);
      });

      dataDasboard.nombre.push("Others");
      dataDasboard.prefix.push("OT");
      dataDasboard.data.push(others);
      dataDasboard.colores.push("warning");

      this.statisticsGoal = {
        series: [
          {
            data: dataDasboard.data,
          },
        ],
        chartOptions: {
          colors: dataDasboard.colores,
          xaxis: {
            categories: dataDasboard.prefix,
          },
        },
      };
    },
  },
};
</script>
