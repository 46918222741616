<template>
  <b-card class="earnings-card">
    <h2 class="font-weight-bold mb-25">Coming soon</h2>
    <div class="font-small">Weekly Report</div>
    <b-row class="d-flex align-items-center h-75">
      <b-col md="12" xl="6">
        <h5 class="mb-1">$4055.56</h5>
        <b-card-text class="text-muted font-small-2">
          <span class="font-weight-bolder">68.2%</span
          ><span> more earnings than last month.</span>
        </b-card-text>
      </b-col>
      <b-col md="12" xl="6">
        <vue-apex-charts
          height="70"
          :options="statisticsOrder.chartOptions"
          :series="statisticsOrder.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

const $barColor = "#f3f3f3";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      statisticsOrder: {
        series: [
          {
            name: "2020",
            data: [45, 85, 65, 45, 65],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "20%",
              startingShape: "rounded",
              colors: {
                backgroundBarColors: [
                  $barColor,
                  $barColor,
                  $barColor,
                  $barColor,
                  $barColor,
                ],
                backgroundBarRadius: 5,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.success],

          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    };
  },
};
</script>
