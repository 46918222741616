<template>
  <b-card v-if="data" class="earnings-card">
    <b-row>
      <b-col cols="6">
        <div class="h-50">
          <h3 class="font-weight-bold m-0">Individual Report</h3>
          <p class="text-muted">Visit Types</p>
        </div>
        <div>
          <h2 class="font-weight-bolder">{{ totalVPD }}</h2>
          <span>
            <b-badge variant="light-success">Total VPD</b-badge>
          </span>
        </div>
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          type="bar"
          height="145"
          :options="MonthStats.chartOptions"
          :series="MonthStats.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      MonthStats: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: { show: false },
          },
          grid: {
            show: true,
            padding: {
              left: 10,
              right: 0,
            },
          },
          colors: [$themeColors.success, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: "20%",
              distributed: true,
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            followCursor: true,
          },
          xaxis: {
            categories: [],
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          legend: {
            show: false,
          },
        },
      },
    };
  },
  computed: {
    totalVPD() {
      const types = this.data;
      const { priority, others } = types;
      return parseInt(priority.quantity) + parseInt(others.quantity);
    },
  },
  methods: {
    getData() {
      const types = this.data;
      const { priority } = types;

      let dataDasboard = {
        nombre: [],
        data: [],
        colores: [],
      };

      priority.types.forEach((item) => {
        dataDasboard.nombre.push(item.prefix);
        dataDasboard.data.push(item.sum);
        dataDasboard.colores.push(item.color);
      });

      dataDasboard.nombre.push("OT");
      dataDasboard.data.push(priority.others);
      dataDasboard.colores.push("warning");

      this.MonthStats = {
        series: [
          {
            name: "Type Visit",
            data: dataDasboard.data,
          },
        ],
        chartOptions: {
          colors: dataDasboard.colores,
          labels: dataDasboard.nombre,
        },
      };
    },
  },
};
</script>
