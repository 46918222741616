<template>
  <div>
    <section v-if="dashboard" id="dashboard-analytics">
      <b-row class="match-height">
        <b-col lg="6" md="12">
          <TypeReason :dashboardData="dashboard.type_visits" />
          <!-- <analytics-congratulation :data="data.congratulations" /> -->
        </b-col>
        <b-col lg="6" sm="12" v-if="rolMain.id !== 1">
          <DataClinicStats :data="dashboard.statistics" />
        </b-col>
        <template v-else>
          <b-col lg="4" sm="6">
            <PatientStats
              v-if="data.ordersRecevied"
              :patient="{
                total: dashboard.patient_total,
              }"
            />
          </b-col>
          <b-col lg="2" sm="6">
            <b-row class="match-height">
              <b-col cols="12"
                ><RolTotal
                  :patientTotal="{
                    total: 4,
                    name: 'Nurse',
                  }"
              /></b-col>
              <b-col cols="12"
                ><RolTotal
                  :patientTotal="{
                    total: 7,
                    name: 'OMA',
                  }"
              /></b-col>
            </b-row>
            <!-- <statistic-card-with-area-chart
              v-if="data.subscribersGained"
              icon="UsersIcon"
              :statistic="
                kFormatter(data.subscribersGained.analyticsData.subscribers)
              "
              statistic-title="Subscribers Gained"
              :chart-data="data.subscribersGained.series"
            /> -->
          </b-col>
        </template>
      </b-row>

      <b-row class="match-height">
        <template v-if="rolMain.id === 1">
          <!-- <b-col lg="12">
            <ClinicStats :facilityDasboard="dashboard.stats" />
          </b-col> -->
        </template>
        <template v-else>
          <b-col lg="6">
            <b-row>
              <b-col lg="6">
                <statistic-card-horizontal
                  icon="BarChartIcon"
                  :statistic="dashboard.individual.avgNc"
                  statistic-title="Average New Consultation Individual"
                />
              </b-col>
              <b-col lg="6">
                <statistic-card-horizontal
                  icon="BarChartIcon"
                  :statistic="dashboard.individual.avgNp"
                  statistic-title="Average New Patient Individual"
                />
              </b-col>
              <b-col lg="12">
                <statistic-card-horizontal
                  icon="BarChartIcon"
                  :statistic="dashboard.individual.avgTotal"
                  statistic-title="Average Total Individual"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-row>
              <b-col lg="12">
                <statistic-card-horizontal
                  icon="UsersIcon"
                  :statistic="dashboard.statustics_vpd.vpd"
                  statistic-title="VPD"
                  color="warning"
                />
              </b-col>
              <b-col lg="6">
                <statistic-card-horizontal
                  icon="UsersIcon"
                  :statistic="dashboard.statustics_vpd.npvpd"
                  statistic-title="NPVPD"
                  color="warning"
                />
              </b-col>
              <b-col lg="6">
                <statistic-card-horizontal
                  icon="UsersIcon"
                  :statistic="dashboard.statustics_vpd.vpdt"
                  statistic-title="VPDT"
                  color="warning"
                />
              </b-col>
            </b-row>
          </b-col>
        </template>
        <b-col lg="12">
          <DashboardStatsGeneral :isAdmin="true"/>
          <!-- <analytics-support-tracker :data="data.supportTracker" /> -->
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col lg="8">
          <VisitWeek
            v-if="rolMain.id !== 1"
            :dashboardData="dashboard.visits_week"
          />
        </b-col>
        <b-col lg="4"> </b-col>
      </b-row>

      <b-row class="match-height">
        <template>
          <b-col lg="4">
            <analytics-timeline :data="data.timeline" />
          </b-col>
        </template>
        <b-col lg="4">
          <analytics-sales-radar-chart :data="data.salesChart" />
        </b-col>
        <b-col lg="4">
          <analytics-app-design :data="data.appDesign" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <invoice-list />
        </b-col>
      </b-row>
    </section>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BCarousel,
  BCarouselSlide,
  BOverlay,
} from "bootstrap-vue";

import { kFormatter } from "@core/utils/filter";
import InvoiceList from "@/views/apps/invoice/invoice-list/InvoiceList.vue";
import AnalyticsCongratulation from "./AnalyticsCongratulation.vue";
import AnalyticsSupportTracker from "./AnalyticsSupportTracker.vue";
import AnalyticsTimeline from "./AnalyticsTimeline.vue";
import AnalyticsSalesRadarChart from "./AnalyticsSalesRadarChart.vue";
import AnalyticsAppDesign from "./AnalyticsAppDesign.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";

import TypeReason from "./stats/TypeReason.vue";
import ClinicStats from "./stats/ClinicStats.vue";
import DataClinicStats from "./stats/DataClinicStats";
import PatientStats from "./stats/PatientStats";
import RolTotal from "./stats/RolTotal";
import VisitWeek from "./stats/VisitWeek";
import DashboardStatsGeneral from "./stats/DashboardStatsGeneral";

import DashboardAdmin from "./DashboardAdmin";
import DashboardMedical from "./DashboardMedical";

import axiosD from "@/services/dashboard";

import ToastNotification from "@/components/ToastNotification";

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BCarousel,
    BCarouselSlide,
    BOverlay,
    AnalyticsCongratulation,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    AnalyticsAppDesign,
    InvoiceList,

    ToastNotification,

    TypeReason,
    ClinicStats,
    DataClinicStats,
    RolTotal,
    PatientStats,
    VisitWeek,
    StatisticCardHorizontal,
    DashboardStatsGeneral,

    DashboardAdmin,
    DashboardMedical,
  },
  data() {
    return {
      isBanner: false,
      data: {},
      images: [],
      dashboard: null,
      loading: false,
      rolMain: JSON.parse(localStorage.getItem("userData")).roles[0],
    };
  },
  watch: {
    "$variableGlobal.facility": function (value) {
      this.dashboard = null;
      this.$refs.toast.success("Has successfully moved to another facility");
      this.getDasboard();
    },
  },
  created() {
    // auth pusher
    // this.$pusher.config.auth.headers.Authorization = `Bearer ${localStorage.getItem(
    //   "accessToken"
    // )}`;

    const data = JSON.parse(localStorage.getItem("userData"));
    this.$http.get("/analytics/data").then((response) => {
      response.data.congratulations.name = data.name;
      this.data = response.data;
    });

    this.getDasboard();
  },
  methods: {
    kFormatter,
    getDasboard() {
      this.loading = true;
      axiosD.dashboardAdminList().then((res) => {
        this.loading = false;
        this.dashboard = res;
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
