<template>
  <b-card class="card card-congratulations">
    <!-- images -->
    <div
      v-if="isComplete"
      :style="{
        'background-image':
          'url(' + require('@/assets/images/elements/CONFETI_OMA.svg') + ')',
      }"
      class="confeti-oma"
    ></div>

    <div
      class="d-flex flex-column justify-content-between align-items-start h-100 text-left"
    >
      <div class="position-button" style="z-index: 0">
        <h3 class="mb-0 pb-0 text-white">
          {{ title }}
        </h3>
        <p class="mt-1 text-width">
          <span>
            {{ message }}
          </span>
          <br />
        </p>
        <b-button variant="primary" @click="modalShow = true">
          View Bonus
        </b-button>
      </div>
    </div>
    <b-img
      v-if="isComplete && isCompletedProgress == 0"
      class="medal-congrulations"
      :src="require('@/assets/images/elements/MEDALLA.svg')"
      width="30%"
    >
    </b-img>
    <b-img
      v-if="isComplete && isCompletedProgress >= 1"
      class="medal-congrulations width-custom"
      :src="require('@/assets/images/elements/ACUMULADO.svg')"
      width="40%"
    >
    </b-img>
    <b-img
      v-if="!isComplete"
      class="doctor-no-complete"
      :src="require('@/assets/images/elements/doctorIndio2.png')"
    >
    </b-img>
    <span
      v-if="isComplete && isCompletedProgress >= 1"
      class="number-of-step font-weight-bolder"
      >{{ isCompletedProgress }}</span>

    <BonusModal
      v-if="modalShow"
      :data="dataBonus"
      @onHideModal="modalShow = false"
    />
    <!--/ images -->
  </b-card>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText, BButton } from "bootstrap-vue";
import BonusModal from "../components/BonusModal";

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
    BButton,

    BonusModal,
  },
  props: {
    isComplete: {
      type: Boolean,
      required: true,
    },
    isCompletedProgress: {
      type: Number,
      required: true,
    },
    cashNow: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    dataBonus: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      modalShow: false,
    };
  },
  computed: {
    fullName() {
      return `${this.userData.name}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.confeti-oma {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 28%;
  height: 100%;
  width: 100%;
}
.text-width {
  font-size: 14px;
  width: 65%;
}
.medal-congrulations {
  position: absolute;
  top: 50px;
  right: 25px;
  width: 5rem;
}
.doctor-no-complete {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 7em;
}

.width-custom {
  width: 6rem;
}
.number-of-step {
  position: absolute;
  top: 51px;
  right: 2.3rem;
  font-size: 13px;
}
.position-button {
  z-index: 100;
}
</style>
