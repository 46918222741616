<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="lg"
  >
    <section id="dashboard-ecommerce">
      <b-row class="match-height" v-if="dashboard">
        <b-col xl="4" md="6">
          <DashboardMedal
            v-if="dashboard.daily_income"
            :data="dashboard.daily_income"
            :dataBonus="dashboard.bonusHistory"
            @showGoals="showGoals"
          />
        </b-col>
        <b-col xl="8" md="6">
          <DashboardStatistics
            v-if="dashboard.individual"
            :data="dataStatics"
          />
        </b-col>
      </b-row>

      <b-row class="match-height" v-if="dashboard">
        <b-col lg="4">
          <b-row class="match-height">
            <b-col lg="6" md="6" cols="6">
              <DashboardVpd
                v-if="dashboard.goals"
                :data="dashboard.goals"
              />
            </b-col>
            <b-col xl="6" md="6" cols="6">
              <DashboardIncome
                v-if="dashboard.daily_income_progress"
                :data="dashboard.daily_income_progress"
              />
            </b-col>
            <!-- Bar Chart - Orders -->
            <b-col lg="12" md="6">
              <DashboardIndividualReport
                v-if="dashboard.individual_type_visits"
                :data="dashboard.individual_type_visits"
              />
            </b-col>
          </b-row>
        </b-col>

        <!-- Revenue Report Card -->
        <b-col lg="8">
          <DashboardGoalFacility
            v-if="dashboard.global_type_visits"
            :data="dashboard.global_type_visits"
            :cash="dashboard.daily"
            @showGoals="showGoals"
          />
        </b-col>
        <!--/ Revenue Report Card -->
      </b-row>

      <b-row class="match-height">
        <!-- Browser States Card -->
        <b-col lg="4" md="6">
          <DashboardForms v-if="dashboard.bonusHistory" :data="dashboard.bonusHistory" />
        </b-col>
        <!--/ Browser States Card -->

        <!-- Goal Overview Card -->
        <b-col :lg="rolMain.id == 3 ? 8 : 4" md="6">
          <ecommerce-goal-overview
            v-if="dashboard.goal_user && rolMain.id == 4"
            :data="dashboard.goal_user"
          />
          <DashboardStatsGeneral
            v-if="dashboard.omaStistics && rolMain.id == 3"
            :data="dashboard.omaStistics"
          />
        </b-col>

        <!-- Transaction Card -->
        <b-col lg="4" md="6" v-if="rolMain.id == 4">
          <b-row class="match-height h-100">
            <b-col lg="12" md="6">
              <DashboardBonus />
            </b-col>
            <b-col lg="12" md="6">
              <DashboardInformation class="match-height" :data="data.meetup" />
            </b-col>
          </b-row>
        </b-col>
        <!--/ Transaction Card -->
      </b-row>
      <b-row>
        <b-col cols="12">
          <DashboardTable />
        </b-col>
      </b-row>
      <b-modal
        id="modal-lg"
        v-model="conditionGoals"
        centered
        size="lg"
        hide-footer
        hide-header
        @hide="onHide"
      >
        <DashboardDetails v-if="dataGoal" :data="dataGoal"></DashboardDetails>
      </b-modal>
      <ToastNotification ref="toast" />
    </section>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BModal, BOverlay } from "bootstrap-vue";

import DashboardMedal from "./stats/DashboardMedal.vue";
import DashboardIncome from "./stats/DashboardIncome.vue";
import DashboardStatistics from "./stats/DashboardStatistics.vue";
import DashboardVpd from "./stats/DashboardVpd.vue";
import DashboardGoalFacility from "./stats/DashboardGoalFacility.vue";
import DashboardIndividualReport from "./stats/DashboardIndividualReport.vue";
import DashboardTable from "./stats/DashboardTable.vue";
import DashboardInformation from "./stats/DashboardInformation.vue";
import DashboardBonus from "./stats/DashboardBonus.vue";
import DashboardStatsGeneral from "./stats/DashboardStatsGeneral.vue";

import EcommerceMeetup from "./stats/EcommerceMeetup.vue";
import DashboardForms from "./stats/DashboardForms.vue";
import EcommerceGoalOverview from "./stats/DashboardGoalOverview.vue";
import DashboardDetails from "./stats/DashboardDetails.vue";
import InvoiceList from "@/views/apps/invoice/invoice-list/InvoiceList.vue";

import axiosD from "@/services/dashboard";

import ToastNotification from "@/components/ToastNotification";

export default {
  components: {
    BRow,
    BCol,
    BOverlay,

    DashboardMedal,
    DashboardStatistics,
    DashboardIncome,
    DashboardVpd,
    DashboardIndividualReport,
    DashboardTable,
    EcommerceMeetup,
    DashboardForms,
    EcommerceGoalOverview,
    DashboardGoalFacility,
    DashboardDetails,
    DashboardInformation,
    DashboardBonus,
    DashboardStatsGeneral,
    InvoiceList,

    ToastNotification,
    BModal,
  },
  data() {
    return {
      data: {},
      dataAnalytics: {},
      dataStatics: [],
      dashboard: [],
      conditionGoals: false,
      dataGoal: null,
      loading: false,
      rolMain: JSON.parse(localStorage.getItem("userData")).roles[0],
    };
  },
  watch: {
    "$variableGlobal.facility": function (value) {
      this.dashboard = null;
      this.dataStatics = [];
      this.$refs.toast.success("Has successfully moved to another facility");
      this.getDasboard();
    },
  },
  created() {
    this.$http.get("/analytics/data").then((response) => {
      this.dataAnalytics = response.data;
    });
    // data
    this.$http.get("/ecommerce/data").then((response) => {
      this.data = response.data;
    });
    this.getDasboard();
  },
  methods: {
    getDasboard() {
      this.loading = true;
      axiosD.dashboardList().then((res) => {
        this.loading = false;

        const statsAvg = res.individual;

        const colors = ["info", "success", "warning", "primary"];
        const icon = [
          {
            src: require("@/assets/images/icons/transactions.svg"),
            isImg: true,
          },
          {
            src: require("@/assets/images/icons/grossSale.svg"),
            isImg: true,
          },
          {
            src: "TrendingDownIcon",
            isImg: false,
          },
          {
            src: "DollarSignIcon",
            isImg: false,
          },
        ];
        const showOver = [
          "Es el número de pacientes atendidos por cada OMA en 1 día.",
          "Es la sumatoria de la facturación obtenida en las categorías de NP y ES dividida entre el total de pacientes atendidos en dichas categorías por cada OMA en 1 mes.",
          "Es la sumatoria de la facturación obtenida en las categorías de FP y RS dividida entre el total de pacientes atendidos en dichas categorías por cada OMA en 1 mes.",
          "Es la sumatoria de la facturación obtenida en todas las categorías NP, ES, FP, RS y OT dividida entre el total de transacciones realizadas en dichas categorías por cada OMA en 1 mes.",
        ];
        statsAvg.forEach((item, index) => {
          this.dataStatics.push({
            cant: item.value,
            subtitle: item.name,
            color: colors[index],
            icon: icon[index],
            hover: showOver[index],
            isExplanation:
              item.explained && item.explained.length > 0 ? true : false,
            explanation:
              item.explained && item.explained.length > 0
                ? item.explained
                : null,
          });
        });

        this.dashboard = res;
      });
    },
    showGoals(e) {
      if (e === true) {
        axiosD.dashboardGoalDetails().then((res) => {
          this.dataGoal = res;
        });
      }
      this.conditionGoals = e;
    },
    onHide() {
      this.conditionGoals = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
