<template>
  <b-card no-body>
    <b-card-header class="d-flex">
      <b-card-title>Patients</b-card-title>
      <v-select
        v-model="changeDate"
        class="style-chooser"
        :options="datesChart"
      />
    </b-card-header>
    <b-card-body class="pb-0 mx-auto">
      <b-avatar class="mb-1" variant="light-info" size="60">
        <feather-icon size="21" icon="UsersIcon" />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder text-center">
          {{ total }}
        </h2>
        <span>Patients</span>
      </div>
    </b-card-body>

    <!-- <vue-apex-charts
      type="area"
      height="100"
      width="100%"
      :options="chartOptionsComputed"
      :series="chartData"
    /> -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BAvatar,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

import axiosD from "@/services/dashboard/index.js";

import vSelect from "vue-select";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAvatar,
    vSelect,
  },
  props: {
    patient: {
      type: Object,
      required: true,
    },
  },
  watch: {
    changeDate(newValue) {
      if (newValue === null) {
        this.changeDate = "all";
        return;
      }
      this.changeDatePatient();
    },
  },
  data() {
    return {
      datesChart: ["today", "week", "month", "all"],
      changeDate: "all",
      total: this.patient.total
    };
  },
  methods: {
    changeDatePatient() {
      axiosD.patientStats(this.changeDate).then(({ total }) => {
        this.total = total;
      });
    },
  },
};
</script>
