<template>
  <div>
    <DailyProgress
      :isComplete="data.goal_complete"
      :cashNow="data.current_avg"
      :title="`${onChangeMessageTitle} ${userData}`"
      :message="onChangeMessage"
      :isCompletedProgress="data.count_bonus"
      :dataBonus="dataBonus.monthly"
    />
  </div>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText } from "bootstrap-vue";
import DailyProgress from "./daily/DailyProgress.vue";

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
    DailyProgress,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    dataBonus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      messageInDaily: {
        initial: "Focus on achieving your daily goals and the monthly goal will be met.",
        bonus: `You have reached the daily goal. Let's keep it up and get more reward!`,
        bonusPlus: `Your progress today is Awesome. Let's keep it up and get the next rewards!`,
      },
      messageInDailyTitle: {
        initial: "Welcome back, ",
        bonus: `Congratulations, `,
      },
      userData: JSON.parse(localStorage.getItem("userData")).name,
    };
  },
  computed: {
    onChangeMessage() {
      if (!this.data.goal_complete) {
        return this.messageInDaily.initial;
      } else if (this.data.goal_complete && this.data.count_bonus > 0) {
        return this.messageInDaily.bonusPlus;
      } else {
        return this.messageInDaily.bonus;
      }
    },
    onChangeMessageTitle() {
      if (!this.data.goal_complete) {
        return this.messageInDailyTitle.initial;
      } else {
        return this.messageInDailyTitle.bonus;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
