<template>
  <b-modal
    id="modal-1"
    title="Current Month Bonus"
    hide-footer
    size="xl"
    centered
    :visible="modalShow"
    @hide="onHideModal"
    hide-header-close
    body-class="p-0"
  >
    <div class="d-flex align-items-center justify-content-center">
      <b-table :items="data" bordered :fields="fields" class="mx-1" foot-clone>
        <template #cell(bonus)="data">
          <i>$ {{ data.item.bonus }} </i>
        </template>
        <template #cell(total)="data">
          <i>$ {{ data.item.total }} </i>
        </template>
        <template #cell(net_sale)="data">
          <i>$ {{ data.item.net_sale }} </i>
        </template>
        <template #cell(total_bonus)="data">
          <i> $ {{ getSum(data.item) }} </i>
        </template>
        <template #cell(ekg)="data">
          <i> $ {{ data.item.ekg }} </i>
        </template>
        <template #cell(smg_x_units)="data">
          <i> $ {{ data.item.smg_x_units }} </i>
        </template>
        <template #cell(smg_x4_units)="data">
          <i> $ {{ data.item.smg_x4_units }} </i>
        </template>

        <template #foot(day)="">
          <i>Total</i>
        </template>

        <template #foot(total)="">
          <i></i>
        </template>
        <template #foot(net_sale)="">
          <i></i>
        </template>

        <template #foot(ekg)="">
          <i v-if="!isOma"> $ {{ getSumBonus }}</i>
          <i v-if="isOma"></i>
        </template>

        <template #foot(bonus)="">
          <i></i>
        </template>
        <template #foot(smg_x_units)="">
          <i></i>
        </template>
        <template #foot(smg_x4_units)="">
          <i></i>
        </template>
        <template #foot(sonograms)="">
          <i></i>
        </template>

        <template v-if="isOma" #foot(total_bonus)="">
          <i>$ {{ getAllBonus }}</i>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BTable } from "bootstrap-vue";
export default {
  components: {
    BModal,
    BTable,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    getSumBonus() {
      return this.data.reduce((total, item) => {
        total += Number(item.ekg) + Number(item.bonus);
        return total;
      }, 0);
    },
    getAllBonus() {
      return this.data.reduce((total, item) => {
        const ekgValue = this.isNumberOrZero(item.ekg) ? Number(item.ekg) : 0;
        const sonogramsValue = this.isNumberOrZero(item.sonograms)
          ? Number(item.sonograms)
          : 0;
        const bonusValue = this.isNumberOrZero(item.bonus)
          ? Number(item.bonus)
          : 0;

        total += ekgValue + sonogramsValue + bonusValue;

        return total;
      }, 0);
    },
  },
  data() {
    return {
      modalShow: true,
      isOma: true,
      fields: this.getFields(),
    };
  },
  methods: {
    onHideModal() {
      this.$emit("onHideModal");
    },
    getFields() {
      let baseFields = [
        { key: "day", label: "Date", sortable: true },
        { key: "total", label: "Gross sale", sortable: true },
        { key: "net_sale", label: "Net income", sortable: true },
        { key: "bonus", label: "Bonus", sortable: true },
        { key: "ekg", label: "EKG", sortable: true },
        { key: "smg_x4_units", label: "SMG (x4)", sortable: true },
      ];

      if (this.data.some((item) => item.sonograms != "-")) {
        baseFields.push({
          key: "sonograms",
          label: "Sonograms",
          sortable: true,
        });
      }
      baseFields.push({
        key: "total_bonus",
        label: "Total Bonus",
        sortable: true,
      });
      return baseFields;
    },
    getSum(item) {
      const { bonus, ekg, smg_x4_units,sonograms } = item;
      if (isNaN(sonograms)) {
        return Number(bonus) + Number(ekg) + Number(smg_x4_units) ;
      }
      return Number(bonus) + Number(ekg) + Number(smg_x4_units) + Number(sonograms || 0);
    },
    isNumberOrZero(value) {
      return value !== "-";
    },
  },
};
</script>

<style></style>
