<template>
  <b-card no-body>
    <b-card-header class="pb-0 d-flex">
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-25"> Monthly Bonus </b-card-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <b-button variant="primary" @click="openModal()"> Current </b-button>
        <!-- @input="selectSection(0)" -->
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body class="pb-0">
      <vue-apex-charts
        type="bar"
        height="440"
        :options="barChart.chartOptions"
        :series="barChart.series"
      />
      <b-card-text class="text-muted font-small-2">
        <span>
          This table reflects the bonus based on the Daily Income Goal only. This bonus may be different from the bonus shown in the Monthly Bonus chart because the monthly bonus not only reflects the bonus based on the Daily Income Goal, but also on the OMA's Priority AVG above $220.
        </span>
      </b-card-text>
    </b-card-body>
    <BonusModal v-if="modalShow" :data="data.monthly" @onHideModal="modalShow = false"/>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BButton,
  BCalendar,
  BTable,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

import BonusModal from "./components/BonusModal";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    BButton,
    BCalendar,
    BTable,

    BonusModal,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      modalShow: false,
      barChart: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: "bar",
            fontFamily: "Poppins",
            toolbar: {
              show: false,
            },
          },
          colors: [],
          dataLabels: {
            enabled: false,
            formatter: function (val) {
              return `${val} %`;
            },
            offsetX: -10,
            background: {
              enabled: true,
              borderWidth: 4,
              opacity: 0.2,
            },
            style: {
              colors: [
                $themeColors.success,
                $themeColors.primary,
                $themeColors.sidebar,
              ],
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: "50%",
              distributed: true,
              labels: {
                show: true,
              },
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
          },
          legend: {
            show: false,
          },
        },
      },
    };
  },
  created() {
    this.getData();
  },
  
  methods: {
    getData() {
      let dataDasboard = {
        nombre: [],
        data: [],
        colores: [],
      };

      this.data.anual.forEach((item) => {
        dataDasboard.nombre.push(item.month_name);
        dataDasboard.data.push(item.bonus);
        if (item.bono != 0) {
          dataDasboard.colores.push($themeColors.primary);
        } else {
          dataDasboard.colores.push("#333");
        }
      });

      this.barChart.series = [
        {
          name: "Bono",
          data: dataDasboard.data,
        },
      ];

      this.barChart.chartOptions.colors = dataDasboard.colores;
      this.barChart.chartOptions.xaxis = {
        categories: dataDasboard.nombre,
      };
    },
    openModal() {
      this.modalShow = true;
    },
  },
};
</script>
