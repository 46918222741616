<template>
  <div>
    <b-card no-body>
      <b-card-header class="d-flex">
        <b-card-title>Type Of Visits</b-card-title>
        <v-select
          v-model="changeDate"
          class="style-chooser"
          :options="datesChart"
        />
      </b-card-header>
      <b-card-body
        v-if="typesAll.length > 0"
        class="d-flex justify-content-around flex-wrap flex-lg-nowrap"
      >
        <!-- chart info -->
        <div class="stats-custom">
          <div
            v-for="(data, index) in typesAll"
            :key="data.name"
            class="d-flex justify-content-between"
            :class="index === chartData.chartInfo - 1 ? 'mb-0' : 'mb-1'"
          >
            <div class="series-info">
              <feather-icon
                icon="BarChart2Icon"
                size="16"
                :style="{
                  color: data.color,
                }"
                :class="data.color"
              />
              <span class="font-weight-bolder ml-75 mr-25">{{ data.name }}</span
              ><span>- {{ data.sum }}</span>
            </div>
            <!-- <div>
            <span>{{ data.upDown }}%</span>
            <feather-icon
              :icon="data.upDown > 0 ? 'ArrowUpIcon' : 'ArrowDownIcon'"
              :class="data.upDown > 0 ? 'text-success' : 'text-danger'"
              class="mb-25 ml-25"
            />
          </div> -->
          </div>
        </div>
        <!-- apex chart -->
        <vue-apex-charts
          type="donut"
          height="200"
          class="my-1"
          :options="sessionsByDeviceDonut.chartOptions"
          :series="sessionsByDeviceDonut.series"
        />
      </b-card-body>
      <b-card-body
        v-else
        class="d-flex flex-column align-items-center flex-wrap flex-lg-nowrap"
        style="height: 200px"
      >
        <feather-icon icon="InfoIcon" size="26" class="text-primary" />
        <h4>data not found</h4>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import vSelect from "vue-select";

import AnalyticsCongratulation from "../AnalyticsCongratulation.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
    AnalyticsCongratulation,
    vSelect,
  },
  props: {
    dashboardData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    changeDate(newValue) {
      if (newValue === null) {
        this.changeDate = "Today";
        return;
      }
      this.getData(newValue);
    },
  },
  created() {
    this.getData();
  },
  data() {
    return {
      chartData: this.dashboardData,
      infoChart: [],
      typesAll: null,
      datesChart: ["Today", "Week", "Month"],
      changeDate: "Today",
      sessionsByDeviceDonut: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Poppins",
            toolbar: {
              show: false,
            },
          },
          labels: [],
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "12px",
              fontFamily: "Poppins",
              colors: ["white"],
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                  value: {
                    show: true,
                    color: "#6ebdb7",
                  },
                },
              },
            },
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
          colors: [
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.danger,
          ],
        },
      },
    };
  },
  methods: {
    getData(date = "Today") {
      let visitDate;
      switch (date) {
        case "Today":
          visitDate = this.dashboardData.visits_day;
          this.typesAll = this.dashboardData.visits_day.types;
          break;
        case "Week":
          visitDate = this.dashboardData.week;
          this.typesAll = this.dashboardData.week.types;
          break;
        case "Month":
          visitDate = this.dashboardData.month;
          this.typesAll = this.dashboardData.month.types;
          break;
      }

      let data = {
        nombre: [],
        cantidad: [],
        colores: [],
      };

      visitDate.types.forEach((item) => {
        data.nombre.push(item.name);
        data.cantidad.push(item.sum);
        data.colores.push(item.color);
      });

      this.sessionsByDeviceDonut = {
        series: data.cantidad,
        chartOptions: {
          labels: data.nombre,
          plotOptions: {
            pie: {
              donut: {
                size: '50%',
                labels: {
                  show: true,
                  value: {
                    show: true,
                    color: "#6ebdb7",
                  },
                  total: {
                    show: true,
                    showAlways: true,
                    color: "#6ebdb7",
                    label: "Total",
                    formatter(w) {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0);
                    },
                  },
                },
              },
            },
          },
          colors: data.colores,
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/include";
.stats-custom {
  height: 20vh;
  overflow: auto;
}
.stats-custom::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.stats-custom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.stats-custom::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

/* Handle on hover */
.stats-custom::-webkit-scrollbar-thumb:hover {
  background: #57a59e;
}
</style>