<template>
  <b-card v-if="data" class="card-transaction">
    <h3 class="font-weight-bolder mb-2 text-center">Coming Soon</h3>
    <!-- <h3 class="font-weight-bolder mb-2">Facility goals progress</h3>
    <div>
      <b-table :items="items" :fields="fields" responsive="sm">
        <template #thead-top="">
          <b-tr>
            <b-th variant="secondary" colspan="3">Priority Categories</b-th>
            <b-th variant="info" colspan="3">Value Categories</b-th>
          </b-tr>
        </template>
      </b-table>
    </div>
    <h5 class="font-weight-bolder mb-2">Total VPD: 0</h5>
    <h5 class="font-weight-bolder mb-2">Total Revenue: 0</h5> -->
  </b-card>
</template>

<script>
import { BCard, BTable, BTr, BTh } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTr,
    BTh,
    BTable,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [
        {
          name: "VPD",
          type1: "",
          type2a: "",
          nameValue: "VPD",
          type2c: "",
          type3: "",
        },
        {
          name: "NP AVG",
          type1: "",
          type2a: "",
          nameValue: "FP AVG",
          type2c: "",
          type3: "",
        },
        {
          name: "NC AVG",
          type1: "",
          type2a: "",
          nameValue: "RS AVG",
          type2c: "",
          type3: "",
        },
        {
          name: "Priority AVG",
          type1: "",
          type2a: "",
          nameValue: "Value AVG",
          type2c: "",
          type3: "",
        },
        {
          name: "Revenue",
          type1: "",
          type2a: "",
          nameValue: "Revenue",
          type2c: "",
          type3: "",
        },
      ],
      fields: [
        { key: "name", label: "Statistics" },
        { key: "type1", label: "Progress" },
        { key: "type2a", label: "Goals" },
        { key: "nameValue", label: "Statistics" },
        { key: "type2c", label: "Progress" },
        { key: "type3", label: "Goals" },
      ],
    };
  },
  methods: {},
};
</script>
